import React,{Suspense} from 'react';

import './App.css';
import { Route } from "react-router-dom";
//import HomePage from './components/pages/home';
//import Photos from './components/pages/galleryImages'
//import Video from './components/pages/galleryYT'

//import { Header } from './components/header/header'
//import Footer from './components/footer/footers'
import {CheckPosition} from './components/hoc/inVieport'

import { polyfillDOMImplementation } from 'interweave-ssr';
let Router;
if (typeof window !== "undefined") {
  Router = require("react-router-dom").BrowserRouter;
} else {
  Router = require("react-router-dom").StaticRouter;
}
polyfillDOMImplementation();
const Header = React.lazy(() => import('./components/header/header'))
const HomePage = React.lazy(() => import('./components/pages/home'))
const Photos = React.lazy(() => import('./components/pages/galleryImages'))
const Video = React.lazy(() => import('./components/pages/galleryYT'))
const Footer = React.lazy(()=>import('./components/footer/footers'))
function App() {
  
  const Header2=CheckPosition(Header)
  return (
    <>
         <Suspense fallback={<div>ładowanie</div>} >
      <Router>
      <Header2 startHeight='400' cName="home" endHeight='0' middleHeight='300' startClass='fixed' middleClass='' endClass='' className="main"/>
        <Route exact path="/"  component={HomePage} />
        <Route exact path="/foto" component={Photos} />
        <Route exact path="/video" component={Video} />
         <Footer />
        </Router>
        </Suspense>
    </>
  );
}

export default App;
