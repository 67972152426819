
import React, { Component } from "react";


 function CheckPosition(WrappedComponent, props) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        additionalClass: "",
      };
    }

    componentDidMount() {
      window.addEventListener("scroll", this.handleScroll);
    }
    componentWillUnmount() {
      window.removeEventListener("scroll", this.handleScroll);
      this._isMounted = false;
    }

    handleScroll = props => {
      if (
        Math.abs(document.documentElement.scrollTop) >= this.props.endHeight
      ) {
        this.setState({ additionalClass: this.props.startClass });
      }
      if (
        Math.abs(document.documentElement.scrollTop) <= this.props.middleHeight
      ) {
        if (
          this.state.additionalClass === this.props.startClass ||
          this.state.additionalClass ===
            this.props.startClass + " " + this.props.middleClass
        ) {
          this.setState({
            additionalClass:
              this.props.startClass + " " + this.props.middleClass,
          });
        }
      }
      if (
        Math.abs(document.documentElement.scrollTop) <= this.props.endHeight
      ) {
        this.setState({ additionalClass: this.props.endClass });
      }
    };
    shouldComponentUpdate(nextprops, nextState) {
      if (this.state.additionalClass !== nextState.additionalClass) {
        return true;
      }

      return false;
    }

    render() {
      return (
        <WrappedComponent
          additionalClass={this.state.additionalClass}
          {...this.props}
        />
      );
    }
  };
}
/**
function InViewPort(WrappedComponent, props) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        additionalClass: "",
      };
    }
    componentDidMount() {
      window.addEventListener("scroll", this.handleScroll);
    }
    componentWillUnmount() {
      this._isMounted = false;
      window.removeEventListener("scroll", this.handleScroll);
    }
    handleScroll = () => {
      let bounding = ReactDOM.findDOMNode(this).getBoundingClientRect();
      if (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <=
          (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)
      ) {
        this.setState({ additionalClass: this.props.additionalClass });
      }
    };

    render() {
      this.props = Object.assign(
        { additionalClass: this.state.additionalClass },
        this.props
      );

      return <WrappedComponent {...this.props} />;
    }
  };
} 
**/
 async function getData(apiUrl) {
  try {
    const response = await fetch(apiUrl);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

async function sendData(url, data) {
  try {
    const response = await fetch(url, {
      method: "POST", // or 'PUT'
      body: JSON.stringify(data),

      headers: {
        "Content-Type": "application/json",
      },
    });
    const stat = await response.ok;
    return stat;
  } catch (error) {
    return false;
  }
}

export {  getData, sendData,CheckPosition };
